/*
 * App Global CSS
 * ----------------------------------------------------------------------------
 * Put style rules here that you want to apply globally. These styles are for
 * the entire app and not just one component. Additionally, this file can be
 * used as an entry point to import other CSS/Sass files to be included in the
 * output CSS.
 * For more information on global stylesheets, visit the documentation:
 * https://ionicframework.com/docs/layout/global-stylesheets
 */

/* Core CSS required for Ionic components to work properly */
@import "@ionic/angular/css/core.css";

/* Basic CSS for apps built with Ionic */
@import "@ionic/angular/css/normalize.css";
@import "@ionic/angular/css/structure.css";
@import "@ionic/angular/css/typography.css";
@import "@ionic/angular/css/display.css";

/* Optional CSS utils that can be commented out */
@import "@ionic/angular/css/padding.css";
@import "@ionic/angular/css/float-elements.css";
@import "@ionic/angular/css/text-alignment.css";
@import "@ionic/angular/css/text-transformation.css";
@import "@ionic/angular/css/flex-utils.css";
@import "mixins";
@import "swiper/scss";
@import "@ionic/angular/css/ionic-swiper";

@font-face {
  font-family: Montserrat;
  src: url("/assets/font/Montserrat-Medium.ttf");
}

@font-face {
  font-family: Montserrat-Bold;
  src: url("/assets/font/Montserrat-Bold.ttf");
}

@font-face {
  font-family: Viga;
  src: url("/assets/font/Viga-Regular.ttf");
}

body {
  font-family: Montserrat, sans-serif;
  background: #000000;
  color: #ffffff;
}

h1,
h2,
h3,
h4,
h5 {
  font-family: Montserrat-Bold, sans-serif;
  font-weight: normal;
  margin: 0;
}

h1 {
  font-size: 24px;
}

h2 {
  font-size: 22px;
}

ion-content {
  --background: #000;
  --padding-start: 24px;
  --padding-end: 24px;
  overflow-y: auto;
}

.default-input {
  margin: 0 0 12px;
  --inner-padding-end: 0px;
  --padding-start: 0px;
  --border-width: 0;
  --background: transparent;
  --border-style: unset;

  ion-select {
    --background: var(--ion-color-gray);
  }

  .input-wrapper {
    padding: 0;
  }

  .native-wrapper {
    background: var(--ion-color-gray);
    border-radius: 5px;
    width: 100%;
    padding: 12px 18px;
    font-family: Montserrat;
    font-size: 14px;
  }
  .label-text-wrapper {
    font-size: 18px !important;
    margin-bottom: 4px;
    color: var(--ion-color-gray-light) !important;
    position: relative;
    bottom: 4px;
    left: 18px;
    @include font-bold();
  }

  ion-select {
    --background: transparent;

    &::part(container) {
      background: var(--ion-color-gray);
      border-radius: 5px;
      padding: 18px 20px;
    }

    &::part(icon) {
      display: none;
    }

    &::part(label) {
      font-size: 18px !important;
      margin-bottom: 4px;
      color: var(--ion-color-gray-light) !important;
      position: relative;
      bottom: 4px;
      left: 18px;
      font-family: Montserrat-Bold, sans-serif !important;
    }
  }

  ion-input {
    //background: var(--ion-color-gray);
    border-radius: 5px;
    --padding-top: 18px !important;
    --padding-bottom: 18px !important;
    --padding-start: 18px !important;
    --padding-end: 50px !important;
    --placeholder-color: #767676;
    --placeholder-opacity: 1;
    --font-family: Montserrat;
    font-size: 14px;
    overflow: hidden;
    input {
      background: #3e3e3e !important;
    }

    input:-webkit-autofill,
    input:-webkit-autofill:hover,
    input:-webkit-autofill:focus,
    input:-webkit-autofill {
      -webkit-text-fill-color: #ffffff;
      -webkit-box-shadow: 0 0 0 1000px var(--ion-color-gray) inset;
      transition: background-color 5000s ease-in-out 0s;
    }

    input:-webkit-autofill::first-line {
      font-family: Montserrat, sans-serif;
      font-size: 14px;
    }
  }

  .clear-icon {
    bottom: calc(50% - 16px);
    transform: translateY(50%);
    z-index: 999;
    height: 17px;
    right: 10px;
    position: absolute;
  }

  .item-native {
    padding: 0 !important;
  }

  &.item-has-focus,
  &.item-has-value {
    --border-width: 0;
  }

  ion-label {
    font-size: 14px !important;
    color: var(--ion-color-gray-light) !important;
    position: relative;
    bottom: 4px;
    left: 18px;
    @include font-bold();
  }
}

.action-btn {
  font-family: Viga, sans-serif !important;
  font-size: 18px;
  height: 60px;
  display: flex;
  align-items: center;
  justify-content: center;
  &:disabled {
    background: gray;
  }
  width: 100%;
  padding: 22px 0;
  text-align: center;
  text-transform: uppercase;
  @include font-bold;
  background: var(--ion-color-primary);
  color: #333333;

  ion-spinner {
    color: #333333;
  }
}

.backend-error-toast,
.backend-success-toast {
  @include font-bold();
  --border-radius: 5px;
  bottom: 0;
  --start: 24px;
  --font-size: 12px;
  --end: 24px;
  font-size: 12px;

  &::part(container) {
    padding: 22px 18px;
    height: 0;
  }
}

.backend-error-toast {
  --background: var(--ion-color-danger);
}

.backend-success-toast {
  --background: var(--ion-color-success);
}

.column {
  display: flex;
  align-items: center;
}

.info {
  background: #000000;
  width: 100%;
  border-radius: 0 0 10px 10px;
  position: absolute;
  bottom: 0;
  left: 0;
  right: 0;
  margin: auto;
  height: 30px;
  display: flex;
  align-items: center;
  border: 2px solid #000;
  .number {
    @include center;
    @include font-bold;
    font-size: 15px;
    border: 2px solid #ffa800;
    box-sizing: border-box;
    border-radius: 0 8px;
    color: #ffa800;
    line-height: 1.2;
    width: 25px;
    height: 100%;
  }
}
.small-rack {
  .info {
    border-radius: 0 0 7px 7px;
    height: 20px;
    border-width: 1px;
    .number {
      font-size: 10px;
      border-width: 1px;
      width: 17px;
    }
  }
}
.stock {
  @include font-bold;
  flex: 1;
  text-align: center;
  text-transform: uppercase;
  color: #8c8c8c;
  font-size: 14px;

  span {
    color: #fff;
  }
  &.qty {
    margin-left: -25px;
  }
}
.small-rack {
  .stock {
    font-size: 7px;
  }
}

.search-wrap {
  margin-top: 38px;
  height: 45px;
  position: relative;
  margin-bottom: 14px;
}

.search-btn {
  width: 45px;
  height: 45px;
  position: absolute;
  right: 0;
  transition: width 0.3s ease;
}

.confirmation-modal {
  &::part(content) {
    background: transparent;
  }

  &::part(backdrop) {
    background: rgba(255, 255, 255, 0.25);
    backdrop-filter: blur(10px);
    --backdrop-opacity: 1;
  }
}

app-confirmation-modal {
  display: flex !important;
  justify-content: center !important;
  align-items: center;
}

.actions {
  display: flex;
  border-radius: 0 0 11px 11px;
  padding: 20px 25px;
  border: 2px solid #252525;
  .btn {
    width: 100%;
    font-family: "Viga", sans-serif;
    padding: 11px;
    font-size: 18px;
    text-align: center;
    border: 2px solid #3d3d3d;
    border-radius: 5px;
    color: #3d3d3d;

    &:first-child {
      margin-right: 7px;
    }

    &:last-child {
      margin-left: 7px;
    }

    &.filled {
      background: #78ffae;
      border: 2px solid #78ffae;
      color: #000;
    }
  }
}

.subsystem-modal {
  --width: 100% !important;
  --height: 100% !important;
  .modal-wrapper {
    width: 100%;
    height: 100%;
  }
}

.error-reason {
  padding: 10px 25px;
  font-size: 12px;
  color: #252525;
  background: #ff5151;
}

.state-machine {
  display: flex;
  width: 100%;
  background: #252525;
  margin-top: 20px;
  align-items: center;
  padding: 10px 25px;

  &__icon {
    width: 37px;
    height: 37px;
    background: #434344;
    border-radius: 3px;
    display: flex;
    justify-content: center;
    align-items: center;
  }

  &__status {
    flex: 1;
    margin-left: 15px;
    font-size: 14px;
    color: #7b7b7b;
    &--heading {
      font-size: 16px;
      font-family: Montserrat-Bold, sans-serif;
      color: #fff;
    }
  }

  &__btn {
    width: 120px;
    cursor: pointer;
    font-family: "Viga", sans-serif;
    background: #434344;
    height: 30px;
    font-size: 14px;
    border-radius: 3px;
    display: flex;
    justify-content: center;
    align-items: center;
  }

  &__current {
    font-family: Montserrat-Bold, sans-serif;
    font-size: 14px;
    text-align: right;
    color: #89ee89;
    flex: 0.7;
    &.error {
      color: #ff5151;
    }
  }
}

.full-height {
  height: 100%;
}

.amount-modal {
  --width: 100% !important;
  --height: 100% !important;
  .modal-wrapper {
    width: 100%;
    height: 100%;
    --border-radius: 0;
  }
}

app-replace-product {
  height: 100%;
}

.back {
  display: flex;
  justify-content: flex-start;
  align-items: center;
  width: 25px;
  margin-right: 10px !important;
}

.shelf-chip {
  display: flex;
  justify-content: center;
  align-items: center;
  font-family: Montserrat, sans-serif;
  padding: 3px 12px 4px;
  background: #2d2d2d;
  border-radius: 50px;
  font-weight: 400;
  font-size: 14px;
  margin-left: 10px;
}

.alert-wrapper {
  --background: var(--ion-color-gray-dark);
}

.loader {
  height: 100%;
  background: black;
  width: 100%;
  position: absolute;
  display: flex;
  justify-content: center;
  align-items: center;
  ion-spinner {
    transform: scale(3);
    color: var(--ion-color-primary);
  }
}

.start-btn {
  @include center();
  @include font-bold();
  cursor: pointer;
  width: 220px;
  height: 220px;
  margin: auto;
  border-radius: 100%;
  background: #78ffae;
  color: #252525;
  position: relative;
  box-shadow: 0 0 0 rgba(120, 255, 174, 0.8);
  animation: pulse 1.5s infinite;
  -webkit-animation: pulse 1.5s infinite;
  &:before {
    content: "";
    display: block;
    position: absolute;
    width: 220px;
    height: 220px;
    border-radius: 100%;
    border: 1px solid #666666;
    opacity: 0;
    animation: border-animation 1.5s infinite;
    -webkit-animation: border-animation 1.5s infinite;
  }
  &.small {
    width: 120px;
    height: 120px;
    font-size: 12px;
    animation: pulse-small 1.5s infinite;
    -webkit-animation: pulse-small 1.5s infinite;
    &::before {
      width: 120px;
      height: 120px;
      animation: border-animation-small 1.5s infinite;
      -webkit-animation: border-animation-small 1.5s infinite;
    }
  }
}

.labeling-hint {
  background: radial-gradient(158.05% 396.08% at 0% 0%, rgba(0, 0, 0, 0.4) 0%, rgba(0, 0, 0, 0) 100%);
  border: 1px solid #4e4e4e;
  backdrop-filter: blur(42px);
  border-radius: 10px;
  padding: 18px;
  margin: 0 25px 37px;
  text-align: center;
  &__header {
    color: #78ffae;
    font-size: 18px;
    @include font-bold();
    margin-bottom: 15px;
  }
  &__text {
    font-size: 14px;
    ul {
      list-style-type: none;
      text-align: left;
      padding-inline-start: 18px;
    }
    ul > li:before {
      display: inline-block;
      content: "-";
      width: 1em;
      margin-left: -1em;
    }
  }
  &.recording {
    margin: 0 0 37px;
  }
}

app-category-picker app-search-wrap .search-wrap {
  margin-top: 0 !important;
}

@keyframes pulse {
  0% {
  }
  80% {
    box-shadow: 0 0 50px rgba(120, 255, 174, 0);
  }
  100% {
    box-shadow: 0 0 0 rgba(120, 255, 174, 0);
  }
}

@keyframes pulse-small {
  0% {
  }
  80% {
    box-shadow: 0 0 30px rgba(120, 255, 174, 0);
  }
  100% {
    box-shadow: 0 0 0 rgba(120, 255, 174, 0);
  }
}

@keyframes border-animation {
  0% {
    opacity: 1;
  }
  80% {
    width: 280px;
    height: 280px;
  }
  100% {
    opacity: 0;
  }
}

@keyframes border-animation-small {
  0% {
    opacity: 1;
  }
  80% {
    width: 180px;
    height: 180px;
  }
  100% {
    opacity: 0;
  }
}

@-webkit-keyframes pulse {
  0% {
  }
  80% {
    box-shadow: 0 0 50px rgba(120, 255, 174, 0);
  }
  100% {
    box-shadow: 0 0 0 rgba(120, 255, 174, 0);
  }
}

@-webkit-keyframes border-animation {
  0% {
    opacity: 1;
  }
  80% {
    width: 280px;
    height: 280px;
  }
  100% {
    opacity: 0;
  }
}

.animation {
  position: relative;

  &-wrapper {
    width: 265px;
    height: 265px;
    border-radius: 100%;
    position: relative;
    margin: auto;
    overflow: hidden;
    z-index: 999;
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-pack: end;
    -ms-flex-pack: end;
    justify-content: flex-end;
    background: #292929;

    .hand {
      position: absolute;
      -webkit-transform: translate3d(-150px, -20%, 0);
      transform: translate3d(-150px, -20%, 0);
      left: 0;
      z-index: 3;
      top: 50%;
      -webkit-transition: -webkit-transform 0.5s ease;
      transition: -webkit-transform 0.5s ease;
      -o-transition: transform 0.5s ease;
      transition: transform 0.5s ease;
      transition: transform 0.5s ease, -webkit-transform 0.5s ease;
    }

    .cola {
      position: absolute;
      left: 0;
      z-index: 2;
      top: 50%;
      height: 100%;
      -webkit-transform: translate3d(55px, -20%, 0);
      transform: translate3d(55px, -20%, 0);
      max-height: 60px;
      opacity: 1;
      -webkit-transition: all 0.5s ease;
      -o-transition: all 0.5s ease;
      transition: all 0.5s ease;
    }

    &.animate-1-step {
      .hand {
        -webkit-animation: hand-animation-step-1 2s ease;
        animation: hand-animation-step-1 2s ease;
        -webkit-transition: all;
        -o-transition: all;
        transition: all;
      }

      .cola {
        -webkit-animation: cola-animation-step-1 2s ease;
        animation: cola-animation-step-1 2s ease;
        -webkit-transition: all;
        -o-transition: all;
        transition: all;
      }
    }

    &.finish-1-step {
      .hand {
        -webkit-transform: translate3d(-250px, -20%, 0);
        transform: translate3d(-250px, -20%, 0);
      }

      .cola {
        -webkit-transform: translate3d(100px, calc(-50% - 20px), 0);
        transform: translate3d(100px, calc(-50% - 20px), 0);
        opacity: 0.5;
        max-height: 50px;
      }
    }

    &.animate-2-step {
      .hand {
        -webkit-animation: hand-animation-step-2 2.5s ease;
        animation: hand-animation-step-2 2.5s ease;
        -webkit-transition: all;
        -o-transition: all;
        transition: all;
      }

      .cola {
        -webkit-animation: cola-animation-step-2 2.5s ease;
        animation: cola-animation-step-2 2.5s ease;
        -webkit-transition: all;
        -o-transition: all;
        transition: all;
      }
    }

    &.finish-2-step {
      .hand {
        -webkit-transform: translate3d(-250px, -20%, 0);
        transform: translate3d(-250px, -20%, 0);
      }

      .cola {
        -webkit-transform: translate3d(120px, calc(-50% + 53px), 0);
        transform: translate3d(120px, calc(-50% + 53px), 0);
        opacity: 0.5;
        max-height: 50px;
      }
    }

    &.animate-3-step {
      .hand {
        -webkit-animation: hand-animation-step-3 2.5s ease;
        animation: hand-animation-step-3 2.5s ease;
        -webkit-transition: all;
        -o-transition: all;
        transition: all;
      }

      .cola {
        -webkit-animation: cola-animation-step-3 2.5s ease;
        animation: cola-animation-step-3 2.5s ease;
        -webkit-transition: all;
        -o-transition: all;
        transition: all;
      }
    }

    &.finish-3-step {
      .hand {
        -webkit-transform: translate3d(-250px, -20%, 0);
        transform: translate3d(-250px, -20%, 0);
      }

      .cola {
        -webkit-transform: translate3d(140px, calc(-50% - 19px), 0);
        transform: translate3d(140px, calc(-50% - 19px), 0);
        opacity: 0.5;
        max-height: 50px;
      }
    }
  }
}

@-webkit-keyframes hand-animation-step-1 {
  0% {
    -webkit-transform: translate3d(-170px, -20%, 0);
    transform: translate3d(-150px, -20%, 0);
  }
  50% {
    -webkit-transform: translate3d(-103px, -50%, 0);
    transform: translate3d(-103px, -50%, 0);
  }
  100% {
    -webkit-transform: translate3d(-250px, -20%, 0);
    transform: translate3d(-250px, -20%, 0);
  }
}

@keyframes hand-animation-step-1 {
  0% {
    -webkit-transform: translate3d(-170px, -20%, 0);
    transform: translate3d(-150px, -20%, 0);
  }
  50% {
    -webkit-transform: translate3d(-103px, -50%, 0);
    transform: translate3d(-103px, -50%, 0);
  }
  100% {
    -webkit-transform: translate3d(-250px, -20%, 0);
    transform: translate3d(-250px, -20%, 0);
  }
}

@-webkit-keyframes cola-animation-step-1 {
  0% {
    -webkit-transform: translate3d(55px, -20%, 0);
    transform: translate3d(55px, -20%, 0);
    max-height: 60px;
    opacity: 1;
  }
  50% {
    -webkit-transform: translate3d(100px, calc(-50% - 25px), 0);
    transform: translate3d(100px, calc(-50% - 25px), 0);
    max-height: 60px;
    opacity: 1;
  }
  100% {
    -webkit-transform: translate3d(100px, calc(-50% - 20px), 0);
    transform: translate3d(100px, calc(-50% - 20px), 0);
    opacity: 0.5;
    max-height: 50px;
  }
}

@keyframes cola-animation-step-1 {
  0% {
    -webkit-transform: translate3d(55px, -20%, 0);
    transform: translate3d(55px, -20%, 0);
    max-height: 60px;
    opacity: 1;
  }
  50% {
    -webkit-transform: translate3d(100px, calc(-50% - 25px), 0);
    transform: translate3d(100px, calc(-50% - 25px), 0);
    max-height: 60px;
    opacity: 1;
  }
  100% {
    -webkit-transform: translate3d(100px, calc(-50% - 20px), 0);
    transform: translate3d(100px, calc(-50% - 20px), 0);
    opacity: 0.5;
    max-height: 50px;
  }
}

@-webkit-keyframes hand-animation-step-2 {
  0% {
    -webkit-transform: translate3d(-250px, -20%, 0);
    transform: translate3d(-250px, -20%, 0);
  }
  33% {
    -webkit-transform: translate3d(-103px, -50%, 0);
    transform: translate3d(-103px, -50%, 0);
  }
  66% {
    -webkit-transform: translate3d(-85px, calc(-50% + 70px), 0);
    transform: translate3d(-85px, calc(-50% + 70px), 0);
  }

  100% {
    -webkit-transform: translate3d(-250px, -20%, 0);
    transform: translate3d(-250px, -20%, 0);
  }
}

@keyframes hand-animation-step-2 {
  0% {
    -webkit-transform: translate3d(-250px, -20%, 0);
    transform: translate3d(-250px, -20%, 0);
  }
  33% {
    -webkit-transform: translate3d(-103px, -50%, 0);
    transform: translate3d(-103px, -50%, 0);
  }
  66% {
    -webkit-transform: translate3d(-85px, calc(-50% + 70px), 0);
    transform: translate3d(-85px, calc(-50% + 70px), 0);
  }

  100% {
    -webkit-transform: translate3d(-250px, -20%, 0);
    transform: translate3d(-250px, -20%, 0);
  }
}

@-webkit-keyframes cola-animation-step-2 {
  0% {
    -webkit-transform: translate3d(100px, calc(-50% - 19px), 0);
    transform: translate3d(100px, calc(-50% - 19px), 0);
    opacity: 0.5;
    max-height: 50px;
  }
  33% {
    -webkit-transform: translate3d(100px, calc(-50% - 19px), 0);
    transform: translate3d(100px, calc(-50% - 19px), 0);
    opacity: 0.5;
    max-height: 50px;
  }
  66% {
    -webkit-transform: translate3d(120px, calc(-50% + 47px), 0);
    transform: translate3d(120px, calc(-50% + 47px), 0);
    max-height: 60px;
    opacity: 1;
  }
  100% {
    -webkit-transform: translate3d(120px, calc(-50% + 53px), 0);
    transform: translate3d(120px, calc(-50% + 53px), 0);
    opacity: 0.5;
    max-height: 50px;
  }
}

@keyframes cola-animation-step-2 {
  0% {
    -webkit-transform: translate3d(100px, calc(-50% - 19px), 0);
    transform: translate3d(100px, calc(-50% - 19px), 0);
    opacity: 0.5;
    max-height: 50px;
  }
  33% {
    -webkit-transform: translate3d(100px, calc(-50% - 19px), 0);
    transform: translate3d(100px, calc(-50% - 19px), 0);
    opacity: 0.5;
    max-height: 50px;
  }
  66% {
    -webkit-transform: translate3d(120px, calc(-50% + 47px), 0);
    transform: translate3d(120px, calc(-50% + 47px), 0);
    max-height: 60px;
    opacity: 1;
  }
  100% {
    -webkit-transform: translate3d(120px, calc(-50% + 53px), 0);
    transform: translate3d(120px, calc(-50% + 53px), 0);
    opacity: 0.5;
    max-height: 50px;
  }
}

@-webkit-keyframes hand-animation-step-3 {
  0% {
    -webkit-transform: translate3d(-250px, -20%, 0);
    transform: translate3d(-250px, -20%, 0);
  }
  33% {
    -webkit-transform: translate3d(-85px, calc(-50% + 70px), 0);
    transform: translate3d(-85px, calc(-50% + 70px), 0);
  }
  66% {
    -webkit-transform: translate3d(-65px, -50%, 0);
    transform: translate3d(-65px, -50%, 0);
  }

  100% {
    -webkit-transform: translate3d(-250px, -20%, 0);
    transform: translate3d(-250px, -20%, 0);
  }
}

@keyframes hand-animation-step-3 {
  0% {
    -webkit-transform: translate3d(-250px, -20%, 0);
    transform: translate3d(-250px, -20%, 0);
  }
  33% {
    -webkit-transform: translate3d(-85px, calc(-50% + 70px), 0);
    transform: translate3d(-85px, calc(-50% + 70px), 0);
  }
  66% {
    -webkit-transform: translate3d(-65px, -50%, 0);
    transform: translate3d(-65px, -50%, 0);
  }

  100% {
    -webkit-transform: translate3d(-250px, -20%, 0);
    transform: translate3d(-250px, -20%, 0);
  }
}

@-webkit-keyframes cola-animation-step-3 {
  0% {
    -webkit-transform: translate3d(120px, calc(-50% + 53px), 0);
    transform: translate3d(120px, calc(-50% + 53px), 0);
    opacity: 0.5;
    max-height: 50px;
  }
  33% {
    -webkit-transform: translate3d(120px, calc(-50% + 53px), 0);
    transform: translate3d(120px, calc(-50% + 53px), 0);
    opacity: 0.5;
    max-height: 50px;
  }
  66% {
    -webkit-transform: translate3d(140px, calc(-50% - 25px), 0);
    transform: translate3d(140px, calc(-50% - 25px), 0);
    max-height: 60px;
    opacity: 1;
  }
  100% {
    -webkit-transform: translate3d(140px, calc(-50% - 19px), 0);
    transform: translate3d(140px, calc(-50% - 19px), 0);
    opacity: 0.5;
    max-height: 50px;
  }
}

@keyframes cola-animation-step-3 {
  0% {
    -webkit-transform: translate3d(120px, calc(-50% + 53px), 0);
    transform: translate3d(120px, calc(-50% + 53px), 0);
    opacity: 0.5;
    max-height: 50px;
  }
  33% {
    -webkit-transform: translate3d(120px, calc(-50% + 53px), 0);
    transform: translate3d(120px, calc(-50% + 53px), 0);
    opacity: 0.5;
    max-height: 50px;
  }
  66% {
    -webkit-transform: translate3d(140px, calc(-50% - 25px), 0);
    transform: translate3d(140px, calc(-50% - 25px), 0);
    max-height: 60px;
    opacity: 1;
  }
  100% {
    -webkit-transform: translate3d(140px, calc(-50% - 19px), 0);
    transform: translate3d(140px, calc(-50% - 19px), 0);
    opacity: 0.5;
    max-height: 50px;
  }
}

app-search-wrap.search {
  position: absolute;
  width: 100%;
  right: 0;
  top: 0;
  .search-wrap {
    margin: 0 !important;
  }
}

.input-modal {
  --height: 200px;
  --width: 90%;
  --padding: 20px;
  --border-radius: 4px;
  --backdrop-opacity: 1 !important;
  &::part(backdrop) {
    background: rgba(40, 40, 40, 0.7);
    opacity: 1;
  }

  ion-content {
    --padding: 10px;
    ion-item {
      --background: none;
      --width: auto;
      &::part(native) {
        --border-style: none;
      }
      ion-input {
        &.legacy-input.sc-ion-input-ios-h {
          --padding-start: 10px;
          --padding-end: 10px;
          background: #646464;
        }
      }
    }
  }
}
.swiper-wrapper {
  max-height: 300px;
  padding: 30px 0;
}

.racks {
  display: flex;
  gap: 10px;
  flex-wrap: wrap;
  padding: 0 30px 10px;
  .rack {
    text-align: center;
    cursor: pointer;
    padding: 10px;
    border: 1px solid #4e4e4e;
    border-radius: 3px;
    width: calc(50% - 5px);
    &.active {
      background: #ffa800;
      border: 1px solid #ffa800;
    }
  }
}
