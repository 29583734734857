@mixin desktop {
  @media (min-width: 991px) {
    @content;
  }
}

@mixin font-bold {
  font-family: Montserrat-Bold, sans-serif !important;
}

@mixin center {
  display: flex;
  justify-content: center;
  align-items: center;
}
